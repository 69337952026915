<template>
  <video autoplay controls width="100%" height="100%" id="myVideo" style="background:#000"></video>
  <!--  -->
</template>

<script>
import flvjs from 'flv.js';
import { onMounted } from 'vue';
var flvPlayer,
  videoElement,
  timer,
  isStat = true;
export default {
  props: {
    configuration: String,
  },
  setup(props) {
    const videoPlayer = () => {
      if (flvjs.isSupported()) {
        destroyVideo()
        isStat = true;
        videoElement = document.getElementById('myVideo');
        flvPlayer = flvjs.createPlayer(
          {
            type: 'flv',
            isLive: true,
            url: props.configuration.url,
            cors: true,
            enableWorker: true,
            enableStashBuffer: false,
            stashInitialSize: 128,
            autoCleanupSourceBuffer: true,
          },
          {
            isLive: true,
          }
        );
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();

        flvPlayer.on('statistics_info', res => {
          if (isStat) {
            isStat = false;
            timer = setInterval(() => {
              // 定时追帧
              const end = videoElement?.buffered?.end(0);
              const current = videoElement.currentTime;
              const diff = end - current;
              const diffCritical = 16; // 这里设定了超过4秒以上就进行跳转
              const diffSpeedUp = 1; // 这里设置了超过1秒以上则进行视频加速播放
              const maxPlaybackRate = 4; // 自定义设置允许的最大播放速度
              let playbackRate = 1.0; // 播放速度
              if (diff > diffCritical) {
                videoElement.currentTime = end - 0.45;
                playbackRate = Math.max(1, Math.min(diffCritical, 16));
              } else if (diff > diffSpeedUp) {
                // playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16));
              }
              videoElement.playbackRate = playbackRate;
              if (videoElement.paused) flvPlayer.play();
            }, 5000);
          }
        });

        flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
          // alert("网络波动,正在尝试连接中...");
          console.log(errType, errDetail);
          // errType是 NetworkError时，对应errDetail有：Exception、HttpStatusCodeInvalid、ConnectingTimeout、EarlyEof、UnrecoverableEarlyEof
          // errType是 MediaError时，对应errDetail是MediaMSEError   或MEDIA_SOURCE_ENDED
        });

        flvPlayer.on(flvjs.Events.LOADING_COMPLETE, res => {
          destroyVideo();
          setTimeout(() => {
            videoPlayer();
          }, 10000);
        });
      }
    };

    const destroyVideo = () => {
      isStat = false;
      clearInterval(timer);
      if(flvPlayer) {
        flvPlayer.pause();
        flvPlayer.unload();
        flvPlayer.detachMediaElement();
        flvPlayer.destroy();
        flvPlayer = null;
      }
    };

    const reconnection = () => {
      videoPlayer()
    };
    onMounted(() => {
      videoPlayer();
    });

    return { reconnection };
  },
  unmounted() {
    clearInterval(timer);
    if (flvPlayer) {
      flvPlayer.pause();
      flvPlayer.unload();
      flvPlayer.detachMediaElement();
      flvPlayer.destroy();
      flvPlayer = null;
    }

    console.log('销毁');
  },
};
</script>


