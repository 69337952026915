<template>
  <div class="wrapper live-broadcast-content">
    <div class="live-player">
      <div class="live-player-left">
        <div class="video-title">
          <div class="title-logo">
            <img :src="roomContent.userAvatar" alt="" />
          </div>
          <div class="title-information">
            <div class="title-information-top">
              <h3>{{ roomContent.roomTitle }}</h3>
              <a-button type="primary" @click="onFollow" v-show="roomContent.isAnchor && !roomContent.followed">
                <template #icon><HeartOutlined /></template>
                关注
              </a-button>
            </div>
            <div class="title-information-bottom">
              <div class="information-bottom-box">
                <span>{{ roomContent.nickName }}</span>
                <span>
                  <img src="../../assets/images/live-broadcast-watch/id.png" alt="" />
                  {{ roomContent.id }}
                </span>
                <span class="share-link-box" v-if="getFnCodeList && getFnCodeList.includes('LIVE_SHARE')">
                  <img src="../../assets/images/live-broadcast-watch/share.png" alt="" />
                  <strong @click="shareLink">链接分享</strong>
                  <div class="share-link" v-show="shareIsShow">
                    <div class="share-link-decoration"></div>
                    <div class="share-link-wrapper">
                      <div class="share-link-bottom">
                        <a-input
                          v-model:value="roomContent.shareLink"
                          placeholder="www.luoan88.com/min"
                          ref="linkAddress"
                        />
                        <a-button type="primary" @click="copyLink"> 复制链接 </a-button>
                      </div>
                    </div>
                  </div>
                </span>

                <!-- <span v-if="Boolean(testValue.vehicle)">车辆数{{ testValue.vehicle }} </span>
                <span v-if="Boolean(testValue.peopleNumber)">人流数{{ testValue.peopleNumber }} </span> -->
                <span>
                  <img src="../../assets/images/live-broadcast-watch/clickNumber.png" alt="" />
                  <strong>点击数{{ clickNumberValue }}</strong>
                </span>

                <span>
                  <img src="../../assets/images/live-broadcast-watch/heat.png" alt="" />
                  <strong>热度 {{ peopleNumberValue }}</strong>
                </span>
                <a-select
                  v-if="liveType == 1 && !roomContent.isAnchor"
                  placeholder="请选择算法模式"
                  v-model:value="switchUAV"
                  style="width: 200px"
                  @change="changeSwitchUAV"
                >
                  <a-select-option :value="4">人群聚集与人流量</a-select-option>
                  <a-select-option :value="2">车辆拥堵与车流量</a-select-option>
                  <a-select-option :value="0">关闭算法</a-select-option>
                </a-select>
              </div>
              <a-button type="primary" @click="onCancelFollow" v-show="roomContent.isAnchor && roomContent.followed">
                <template #icon> <HeartFilled /></template>
                取消关注
              </a-button>
            </div>
          </div>
        </div>
        <div class="video-box">
          <div
            class="room-Player-image"
            @click="guideCoverClick"
            v-if="!configuration && ckShow"
            :style="{
              backgroundImage: `url(${guideCover})`,
              display: guideCover ? 'block' : 'none'
            }"
          ></div>

          <!-- ref="videoPubliser" -->
          <video id="video1" v-if="!configuration && ckShow" class="room-Player-object" autoplay muted></video>

          <!-- 用户观看 -->
          <!-- <hls-player class="room-Player" ref="playerLive" v-else :configuration="configuration"></hls-player> -->
          <flv-player class="room-Player" ref="playerLive" v-else :configuration="configuration"></flv-player>

          <!-- 连麦 -->
          <div class="evenwheat" v-if="liveType == 3">
            <!-- 用户 -->
            <a-button type="primary" @click="evenwheat" v-if="roomContent.isAnchor">{{ LianmaiText }}</a-button>
            <!-- 主播 -->
            <a-button type="primary" @click="LianmaiOut" v-else :disabled="LianmaiText == '退出连麦' ? false : true">{{
              LianmaiText
            }}</a-button>
          </div>
          <!-- 预警 -->
          <div class="early-Warning">
            <a-button type="primary"  @click="earlyWarning">预警</a-button>
          </div>
           <!-- 放大按钮 -->
          <div class="enlarge">
            <a-button type="primary" @click="enlarge">视频放大</a-button>
          </div>
          <!-- 重连 -->
          <div class="reconnection">
            <a-button type="primary" @click="reconnectionButton">重连</a-button>
          </div>

          <!-- 退出 -->
          <div class="signout">
            <a-button type="primary" @click="signout">退出</a-button>
          </div>

          <div class="start">
            <a-button type="primary" v-if="!configuration && ckShow" @click="start">开始直播</a-button>
          </div>

         



          <!-- 切换资源 -->
          <div class="video-resources-player" v-show="videoPlayShow">
            <div class="resources-player-content">
              <div class="resources-player-item" v-for="(item, index) in playList" :key="item.resourcesId">
                <CloseOutlined class="close-icon" @click="closePlay(index)" />
                <div class="item-player-video">
                  <ck-player :configuration="item.configuration"></ck-player>
                </div>
                <div
                  class="switch"
                  v-show="!roomContent.isAnchor"
                  v-if="!(!configuration && ckShow)"
                  @click="switchVideo(item)"
                >
                  切换到主屏幕
                </div>
              </div>
              <div class="empty" v-show="playList.length < 2">等待页面进入</div>
            </div>
          </div>

          <div class="player-setup">
            <a-button type="primary" @click="closeLive" v-if="userinfo.isAdmin">强制关闭直播</a-button>
            <a-button type="primary" @click="roomPassword" v-if="!roomContent.isAnchor">设置直播间密码</a-button>
            <a-button type="primary" @click="cancelPassword" v-if="!roomContent.isAnchor">取消密码</a-button>
          </div>
        </div>
      </div>
      <LivePlayerInteractive
        @kickOut="kickOut"
        @yesOnNo="yesOnNo"
        @isLianmai="isLianmai"
        @downcast="downcast"
        @peopleNumber="peopleNumber"
        @clickNumber="clickNumber"
        @switchLive="switchLive"
      />
    </div>
    <div>
      <div style="margin-top:40px">
        <a-tree-select
          v-model:value="treeData"
          style="width: 100%"
          :tree-data="monitorList"
          tree-checkable
          :maxTagCount="1"
          check
          :replaceFields="replaceFields"
          allow-clear
          placeholder="请选择监控视频"
        />
        <div class="reload-db">
          <a-button type="primary" @click="seach_map_point">搜索</a-button>
          <a-button type="primary" @click="map_tennis_point">PDT点位刷新</a-button>
        </div>
      </div>
      <Map @mapData="mapAddVideoPlay" ref="ref_map" :searchMap="searchMap" :playList="playList" />
    </div>
    <div class="history-box">
      <div class="history-title">
        <div>
          <CaretRightOutlined />
        </div>
        <h3>历史回放</h3>
      </div>
      <watch-item :list="historyList" :isShow="false" :historyShow="true" />
    </div>

    <!-- 强制关闭 -->
    <revision-modal
      :title="modal.title"
      :class="[
        modal.close ? 'close-box' : '',
        modal.roomPassword ? 'password-box' : '',
        modal.kickOut ? 'kickOut-box' : '',
        modal.earlyWarning ? 'earlyWarning-box' : '',
        modal.cancelPassword ? 'cancelPassword-box' : ''
      ]"
    >
      <template v-slot:content>
        <div v-html="modal.content"></div>
        <!-- 修改密码 -->
        <a-input v-if="modal.roomPassword" v-model:value="roomPasswordValue" />
        <!-- 发起预警 -->
        <div v-if="modal.earlyWarning">
          <div class="itemBox">
            <span>违法类型：</span>
            <a-select
              v-model:value="earlyWarningObj.illegalType"
              allowClear
              class="itemRight"
              placeholder="请选择违法类型"
            >
              <a-select-option :value="item.id" v-for="(item, i) in TypesViolationList" :key="i">{{
                item.name
              }}</a-select-option>
            </a-select>
          </div>
          <div class="itemBox">
            <span>案件描述：</span>
            <a-textarea
              class="itemRight"
              v-model:value="earlyWarningObj.description"
              placeholder="请输入案件描述"
              :rows="4"
            />
          </div>
          <div class="itemBox">
            <span>证据图片：</span>
            <div class="itemRight" @click="amplification">
              <img :src="earlyWarningObj.evidencePicture" />
            </div>
          </div>
          <div class="itemBox">
            <span>工单派发：</span>
            <a-select
              v-model:value="earlyWarningObj.platform"
              @change="handleChange"
              allowClear
              class="itemRight"
              placeholder="请选择工单派发系统"
            >
              <a-select-option :value="1">综合执法平台</a-select-option>
              <a-select-option :value="2">智慧城管平台</a-select-option>
              <a-select-option :value="3">非接触性执法平台</a-select-option>
            </a-select>
          </div>
          <div class="itemBox" v-show="departmencode_show">
            <span>执法大队：</span>
            <a-select
              v-model:value="earlyWarningObj.departmencode"
              allowClear
              class="itemRight"
              placeholder="请选择执法大队"
            >
              <a-select-option value="直属执法大队">直属执法大队</a-select-option>
              <a-select-option value="拙政园片区综合新政执法大队">拙政园片区综合新政执法大队</a-select-option>
              <a-select-option value="阊门片区新政执法大队">阊门片区新政执法大队</a-select-option>
              <a-select-option value="盘门片区新政执法大队">盘门片区新政执法大队</a-select-option>
              <a-select-option value="葑门片区新政执法大队">葑门片区新政执法大队</a-select-option>
              <a-select-option value="山塘片区新政执法大队">山塘片区新政执法大队</a-select-option>
              <a-select-option value="平江新城综合行政执法大队">平江新城综合行政执法大队</a-select-option>
              <a-select-option value="沧浪新城综合行政执法大队">沧浪新城综合行政执法大队</a-select-option>
              <a-select-option value="金阊新城综合行政执法大队">金阊新城综合行政执法大队</a-select-option>
              <a-select-option value="火车站大队">火车站大队</a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <a-button type="primary" v-show="!modal.earlyWarning" @click="handleCancel" class="button-modal" ghost
          >取消</a-button
        >
        <a-button type="primary" v-show="modal.earlyWarning" @click="agent" class="button-modal">代办</a-button>
        <a-button type="primary" @click="ok" class="button-modal">{{ modal.earlyWarning ? '推送' : '确定'}}</a-button>
      </template>
    </revision-modal>

    <a-drawer title="画框" placement="right" :closable="false" v-model:visible="modifyImage" width="80%">
      <div id="modifyImage">
        <div id="box-canvas">
          <canvas
            id="canvas"
            class="canvas"
            @mousedown="mouseDown($event)"
            @mouseup="mouseUp($event)"
            @mousemove="mouseMove($event)"
          ></canvas>
        </div>
      </div>
      <a-button type="primary" @click="() => (modifyImage = false)">下一步</a-button>
    </a-drawer>
  </div>
</template>

<script>
import CkPlayer from '@/components/CkPlayer.vue';
import FlvPlayer from '@/components/FlvPlayer.vue';
import WatchItem from '@/components/WatchItem.vue';
import Map from '@/components/Map.vue';
import LivePlayerInteractive from '@/components/LivePlayerInteractive.vue';
import { message } from 'ant-design-vue';
import api from '@/services';
import bus from '../../bus';
import { leave } from '@/utils/evenwheat.js';
import { HeartOutlined, HeartFilled, CaretRightOutlined, CloseOutlined } from '@ant-design/icons-vue';
import RevisionModal from '@/components/RevisionModal.vue';
import { ref, onMounted, provide, computed, reactive, nextTick, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { fieldName_history, history_attribute } from '@/store/fieldConfiguration';
import { FormatTime } from '@/utils/tool.js';

// 替换数组建原有字段
const replaceFields = {
  children: 'children',
  title: 'resourceName',
  value: 'id',
  key: 'key'
};

const goBack = async () => {
  await api.liveDown();
  window.history.back();
  history.pushState(null, null, document.URL);
};

export default {
  components: {
    HeartOutlined,
    HeartFilled,
    CloseOutlined,
    CkPlayer,
    LivePlayerInteractive,
    CaretRightOutlined,
    WatchItem,
    Map,
    RevisionModal,
    // HlsPlayer,
    FlvPlayer
    // XgPlayer
  },
  setup() {

    
    const testValue = ref({
      vehicle: 0,
      peopleNumber: 0
    });

    const switchUAV = ref(null);
    // const videoPubliser = ref(null);
    // const videoDom = ref(null);
    const playerLive = ref(null);

    const route = useRoute();
    const userinfo = JSON.parse(localStorage.getItem('SS_userinfo'));

    const liveType = route.query?.liveType;
    // liveType == 1 表示无人机直播
    if (route.query?.liveType == 1) {
      // testValue.value.vehicle = RandomNum(3, 6);
      // testValue.value.peopleNumber = RandomNum(3, 6);
    }
    const configuration = ref(null);

    // 观看或者资源直播
    if (route.query?.pullFlow) {
      console.log(route.query?.pullFlow);
      configuration.value = {
        // width: "100%",
        // height: "91%",
        // id: "mse",
        // useHls: true,
        // playsinline: true,
        // autoplay: true,
        // isLive: route.query?.live,
        url: route.query?.pullFlow
        // hlsOpts: {
        //   xhrSetup: function(xhr, url) {
        //     console.log(xhr, url)
        //     xhr.withCredentials = false;
        //   }
        // }
      };
    }

    // 获取直播间内容
    const roomContent = ref({
      userAvatar: '',
      username: '',
      nickName: '',
      roomTitle: '',
      id: '',

      // 是否关注
      followed: null,
      // 判断是否是主播还是用户
      // flase 主播
      // true 不是主播
      isAnchor: false,
      // 分享链接
      shareLink: '',
      // 播放地址
      videoResourcesVO: null,
      liveSource: ''
    });
    provide('roomContent', roomContent);

    const pushaddress = ref('');
    const state = reactive({
      publiser: null
    });

    const publiserFuc = url => {
      state.publiser = new nmRTC.Publisher({
        id: 'video1',
        stun: [
          {
            urls: 'stun:stun.nodemedia.cn:3478'
          }
        ],
        stunMaxTime: 1000,
        video: {
          width: 1041,
          height: 548,
          bitrate: 1500 * 1000,
          keyInterval: 2
        },
        audio: {
          bitrate: 64 * 1000
        }
      });
      state.publiser.on('start', () => {
        console.log('nmRTC Publisher on start');
      });
      state.publiser.on('stop', () => {
        console.log('nmRTC Publisher on stop');
      });
      state.publiser.on('error', error => {
        console.log('nmRTC Publisher on error', error);
      });
    };

    const stop = () => {
      if (state?.publiser) {
        state?.publiser?.stream.getTracks()[0].stop();
        state?.publiser?.stream.getTracks()[1].stop();
        state?.publiser?.stop();
      }
    };

    const start = () => {
      state.publiser.start(pushaddress.value);
      message.success('推流成功');
    };

    // 弹框的显示和隐藏
    const modal = ref({
      title: '',
      content: ''
    });
    const visible = ref(false);
    provide('visible', visible);
    const getLiveContent = async () => {
      const { data, code } = await api.queryLiveRoom(route.query.id);
      if (code == 500) {
        signout();
      }
      roomContent.value.liveSource = data?.liveSource;
      Object.keys(roomContent.value).forEach(item => {
        roomContent.value[item] = data?.[item];
      });
      roomContent.value.isAnchor = !route.query.live;
    };

    // 监听用户刷新 前进 后推

    // 获取主播的引导页
    const guideCover = ref('');
    const getGuide = async () => {
      const { data } = await api.getLiveRoomCover();
      guideCover.value = data.picture;
    };

    // 历史回放列表
    const historyList = ref();
    const getHistoryList = async () => {
      const { data } = await api.getHistoryList(route.query.id);
      const list = data?.recordVOList || [];
      let flatList = [];
      list.forEach(itme => {
        if (itme.recordVOList.length > 0) itme.recordVOList[0].historyTime = itme.day;
        flatList = flatList.concat(...itme.recordVOList);
      });
      historyList.value = flatList.map(item => {
        let paramsJson = JSON.stringify(item);
        history_attribute.forEach((oldKey, index) => {
          const reg = oldKey;
          paramsJson = paramsJson.replace(reg, fieldName_history[index]);
        });
        return JSON.parse(paramsJson);
      });
    };
    // 关注
    const onFollow = async () => {
      const { data } = await api.follow(route.query.roomUserId);
      roomContent.value.followed = !roomContent.value.followed;
    };

    // 取消关注
    const onCancelFollow = async () => {
      const { data } = await api.cancelFollow(route.query.roomUserId);
      roomContent.value.followed = !roomContent.value.followed;
    };

    // 强制关闭
    const closeLive = () => {
      modal.value = {
        title: '警告',
        content: '若强制关闭直播间，则观众将会全部踢出直播间，无法继续观看，<span>是否关闭</span>？',
        close: true
      };
      visible.value = true;
    };

    const roomPasswordValue = ref('');
    // 房间密码设置
    const roomPassword = () => {
      modal.value = {
        content: `直播间密码设置`,
        roomPassword: true
      };
      visible.value = true;
    };

    // 踢出
    const kickOutParams = ref();
    const kickOut = params => {
      modal.value = {
        title: ' ',
        content: `确认踢出<span>${params.userName}</span>用户吗？`,
        kickOut: true
      };
      visible.value = true;
      kickOutParams.value = params;
    };

    // 分享链接
    let shareIsShow = ref(false);
    const shareLink = async () => {
      if (!shareIsShow.value && !roomContent.value.shareLink) {
        const { data } = await api.linkSharing(route.query.id);
        roomContent.value.shareLink = data;
      }
      shareIsShow.value = !shareIsShow.value;
    };

    // 资源库 添加直播
    const playList = ref([]);
    const videoPlayShow = ref(false);

    const publicvideoPlay = async item => {
      videoPlayShow.value = true;
      item.configuration = {
        id: `${item.resourcesId}`,
        url: '',
        autoplay: false,
        live: false
      };
      if (item.playUrl) {
        item.configuration.url = item.playUrl;
      } else {
        const { data } = await api.watchVideo(item.cameraIndexCode);
        item.configuration.url = data;
      }
      if (playList.value.length >= 2) {
        return false;
      }
      playList.value.push(item);
    };

    bus.on('addVideoPlay', params => {
      publicvideoPlay(params);
    });

    // 切换播放

    // 主播调用摄像头做切换时判断
    // ckShow false 表示 不使用ck true 使用ck
    const ckShow = ref(true);

    const switchVideo = async item => {
      state?.publiser?.stop();
      const { data } = await api.switchVideo({
        resourceType: item.resourceType,
        videoResourceId: item.resourcesId,
        cameraIndexCode: item.indexCode
      });

      ckShow.value = false;
    };

    const switchLive = () => {
      // setTimeout( () => {
      // reconnectionButton()
      // }, 1000)
    };

    // 地图播放
    const mapAddVideoPlay = async value => {
      if (value?.liveAddress) {
        publicvideoPlay({
          playUrl: value?.liveAddress,
          resourcesId: value.videoResourceId
        });
      } else {
        message.info('当前监控不在线');
      }
    };

    // 关闭添加
    const closePlay = index => {
      // console.log(index)
      // console.log(playList.value)
      playList.value.splice(index, 1);

      if (playList.value.length <= 0) videoPlayShow.value = false;
    };

    function getShot(video, x, y, width, height) {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      console.log(video, x, y, width, height);
      ctx.drawImage(video, x, y, width, height);
      return canvas.toDataURL();
    }

    // 发起预警
    const earlyWarningObj = ref({
      // 描述
      description: '',
      // 图片
      evidencePicture: '',
      // 非法类型
      illegalType: null,
      // 画框坐标
      top: null,
      left: null,
      width: null,
      height: null,
      // 列表选择
      platform: null,
      // 直播间ID
      roomId: route.query.id,

      departmencode: null,

      startTime: ''
    });

    // 预警
    const earlyWarning = () => {
      console.log(111)
      getTypesViolation();
      modal.value = {
        earlyWarning: true
      };
      visible.value = true;
      nextTick(() => {
        let dom = document.getElementsByClassName('room-Player')[0];
        console.log(dom);
        // let image = new Image()
        // image.src = getShot(dom, 0, 0, dom.offsetWidth,dom.offsetHeight)
        console.log(dom, 0, 0, dom.offsetWidth, dom.offsetHeight);
        earlyWarningObj.value.evidencePicture = getShot(dom, 0, 0, dom.offsetWidth, dom.offsetHeight);
        // document.body.appendChild(image)
      });
    };

    const modifyImage = ref(false);
    const isflag = ref(false);
    let startX,
      startY,
      ctx,
      isdown = 0,
      canvas,
      imageUrl;
    const amplification = () => {
      modifyImage.value = true;
      if (isflag.value) {
        message.info('画框请重新截图');
        return false;
      }
      isflag.value = true;
      nextTick(() => {
        canvas = document.getElementById('canvas');
        let img = new Image();
        img.src = earlyWarningObj.value.evidencePicture;
        let context = canvas.getContext('2d');
        ctx = context;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          img.classList.add('box-canvas-img');
          document.getElementById('box-canvas').appendChild(img);
          // canvas.style.backgroundImage = img.src;
          // context.clearRect(0, 0, canvas.width, canvas.height);
          // context.drawImage(img, 0, 0, img.width, img.height);
        };
      });
    };

    // 矩型
    const drawIs = ref(false);
    function drawRect(e) {
      if (isdown) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.lineWidth = '3';
        ctx.strokeStyle = '#FF0000';
        ctx.strokeRect(startX, startY, e.offsetX - startX, e.offsetY - startY);

        earlyWarningObj.value.left = startX;
        earlyWarningObj.value.top = startY;
        earlyWarningObj.value.width = e.offsetX - startX;
        earlyWarningObj.value.height = e.offsetY - startY;
      }
    }
    const mouseDown = e => {
      isdown = 1;
      drawIs.value = false;
      startX = e.offsetX; // 鼠标落下时的X
      startY = e.offsetY; // 鼠标落下时的Y
    };
    const mouseUp = e => {
      isdown = 0;
      drawIs.value = true;
    };
    const mouseMove = e => {
      if (drawIs.value) {
        return false;
      }
      drawRect(e);
    };

    // 监听弹框 完成开锁
    watch(
      () => visible.value,
      (val, oldVal) => {
        if (oldVal) {
          isflag.value = false;
          drawIs.value = false;
        }
      }
    );

    // 违法类型
    const TypesViolationList = ref([]);
    const getTypesViolation = async () => {
      const { data } = await api.getTypesViolation();
      TypesViolationList.value = data;
    };

    const msgContent = ref('');

    const LianmaiText = ref('连麦');
    const okObj = {
      // 强制关闭
      close: async () => {
        const { data } = await api.closeLive(route.query.id);
        if (JSON.parse(localStorage.getItem('SS_userinfo'))?.id == route.query.roomUserId) {
          message.info('您的直播间已被超管关闭，请联系超管');
        } else {
          message.info('直播间已被超管关闭');
        }
      },
      kickOut: async () => {
        const { code, data } = await api.kickOut(kickOutParams.value);
        if (!code == 500) {
          message.success('踢出成功');
        }
        visible.value = false;
      },
      roomPassword: async () => {
        const { data } = await api.setPassword({
          password: roomPasswordValue.value
        });
        if (data) {
          handleCancel();
          message.success('密码设置成功');
        }
      },
      earlyWarning: async () => {
        let isGo = true;
        earlyWarningObj.value.startTime = FormatTime(new Date(), 'yyyy-MM-dd hh:mm:ss');
        Object.keys(earlyWarningObj.value).forEach(valueKey => {
          if (!earlyWarningObj.value[valueKey]) {
            if (valueKey == 'departmencode') {
            } else if (valueKey == 'top' || valueKey == 'left' || valueKey == 'height' || valueKey == 'width') {
              message.error('请画框');
            } else {
              message.error('请填写完整');
              isGo = false;
            }
          }
        });
        if (!isGo) {
          return false;
        }
        try {
          const { success } = await api.earlyWarning(earlyWarningObj.value);
          if (success) message.success('预警成功');
          earlyWarningObj.value = {
            // 描述
            description: '',
            // 图片
            evidencePicture: '',
            // 非法类型
            illegalType: null,
            // 画框坐标
            top: null,
            left: null,
            height: null,
            width: null,
            // 列表选择
            platform: null,
            // 直播间ID
            roomId: route.query.id,

            departmencode: null
          };
        } catch (error) {
          message.error('网络错误');
        }
        handleCancel();
      },
      // 主播接受
      yesOnNo: async () => {
        const { data } = await api.yesOnNo({
          attitude: true,
          userName: msgContent.value
        });
        visible.value = false;
        LianmaiText.value = '退出连麦';
      },
      cancelPassword: async () => {
        const { data } = await api.setPassword({
          password: null
        });
        if (data) {
          handleCancel();
          message.success('取消房间私密成功');
        }
      }
    };
    const ok = () => {
      okObj[Object.keys(modal.value).pop()]();
    };

    // 弹窗取消
    const handleCancel = async () => {
      visible.value = false;
      if (modal.value?.yesOnNo) {
        await api.yesOnNo({
          attitude: false,
          userName: msgContent.value
        });
      }
    };

    // 点击引导页面
    const guideCoverClick = () => {
      guideCover.value = '';
    };

    // 复制链接
    const linkAddress = ref(null);
    const copyLink = () => {
      linkAddress.value.input.select();
      document.execCommand('Copy');
    };

    // 连麦
    const evenwheat = async () => {
      // if (roomContent.value.liveSource == 'PC') {
      //   message.info('web端连麦正在升级中，敬请期待！');
      //   return false;
      // }
      if (LianmaiText.value == '退出连麦') {
        LianmaiOut();
        LianmaiText.value = '连麦';
      } else {
        const { code, msg, data } = await api.evenwheat(route.query.id);
        if (code == 500) {
          message.error(msg);
        } else {
          LianmaiText.value = '退出连麦';
          // options.appid = data?.appId;
          // options.token = data?.token;
          // options.channel = route.query.id;
          // try {
          //   await join();
          //   console.log('123212')
          //   message.success('连麦成功');

          // } catch (error) {
          //   console.error(error);
          // }
        }
      }
    };

    // 退出
    const router = useRouter();
    const signout = () => {
      stop();
      LianmaiOut();
      router.go(-1);
    };

    // 主播接受连麦请求
    const yesOnNo = data => {
      msgContent.value = data.msgContent;
      modal.value = {
        title: '连麦请求',
        content: `是否接受${data.msgContent}的连麦请求`,
        yesOnNo: true
      };
      visible.value = true;
    };

    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });

    const isLianmai = data => {
      LianmaiText.value = data;
    };

    // 关闭连麦
    const LianmaiOut = async () => {
      leave();
      const { data } = await api.endCall(route.query.id);
      LianmaiText.value = '连麦';
    };

    const monitorList = ref([]);
    const treeData = ref([]);
    const monitoringTree = async () => {
      const { data, success } = await api.monitoringTree();
      monitorList.value = data;
    };

    const searchMap = ref([]);
    const seach_map_point = async () => {
      const { data, success } = await api.getNearbyCameraByIds({ idList: treeData.value });
      searchMap.value = data;
      // console.log(data)
      // provide('searchMap', data);
    };
    onMounted(() => {
      getGuide();
      getLiveContent();
      getHistoryList();
      monitoringTree();

      if (route.query.address) {
        publiserFuc();
        pushaddress.value = route.query.address;
      }
    });

    const downcast = value => {
      message.info(value.msgContent);
      router.push('/live-broadcast');
    };
    // 取消密码
    const cancelPassword = () => {
      modal.value = {
        title: '提示',
        content: `是否确认取消密码`,
        cancelPassword: true
      };
      visible.value = true;
    };

    const changeSwitchUAV = async value => {
      message.info('算法切换中');
      const { data } = await api.getChangeAlgorithmStatus(value);
      message.success(data);
    };

    const reconnectionButton = () => {
      message.info('正在尝试重新连接，请稍后');
      playerLive.value.reconnection();
    };

    //
    const ref_map = ref(null);
    const map_tennis_point = () => {
      ref_map.value.mapSingleTennis();
    };

    const departmencode_show = ref(false);
    const handleChange = value => {
      if (value == 3) {
        departmencode_show.value = true;
      } else {
        departmencode_show.value = false;
      }
    };

    // 点击数
    const clickNumberValue = ref(0);
    const clickNumber = data => {
      clickNumberValue.value = data.msgContent;
    };
    const peopleNumberValue = ref(0);
    const peopleNumber = data => {
      peopleNumberValue.value = data.length;
    };
    
    
    const keyupEnter = () => {
      document.onkeydown = e =>{
          if (e.keyCode === 69 && e.target.baseURI.match(/live-broadcast-watch/)) {
              earlyWarning()
          }
        }
    }

    nextTick( () => {
      keyupEnter()
    })

    const enlarge = () => {
      router.push({
          path: '/live-broadcast-enlarge',
          query: {
            pullFlow: route.query?.pullFlow,
            id: route.query.id
          }
        });
    }
    // 代办
    const agent = async () => {
      earlyWarningObj.value.startTime = FormatTime(new Date(), 'yyyy-MM-dd hh:mm:ss');
      let params = {
        ...earlyWarningObj.value
      }
      Object.keys(params).forEach((item) => {
        const key = params[item];
        if (key === "" || key === null || key === undefined) {
          delete params[item];
        }
      });
      
      const data = await api.agent(params);
      message.success('代办成功');
    }

    return {
      // falsh直播获取的DOM  直播间内容
      // videoDom,
      roomContent,
      // 分享链接
      shareIsShow,
      shareLink,
      // 播放器参数
      configuration,
      // 关注 取消关注
      onFollow,
      onCancelFollow,
      // 强制关闭 设置密码 踢出
      modal,
      closeLive,
      roomPasswordValue,
      roomPassword,
      kickOut,
      // 资源库播放
      videoPlayShow,
      playList,
      switchVideo,
      closePlay,
      // 弹框
      handleCancel,
      ok,
      // 历史列表
      historyList,
      // 播放判断
      ckShow,
      // 发起预警
      TypesViolationList,
      earlyWarningObj,
      playerLive,
      earlyWarning,
      modifyImage,

      mouseDown,
      mouseUp,
      mouseMove,
      // 用户角色
      userinfo,
      // 主播引导页
      guideCover,
      guideCoverClick,
      // 复制链接
      copyLink,
      linkAddress,
      // 地图
      mapAddVideoPlay,
      // 连麦
      evenwheat,
      yesOnNo,
      getFnCodeList,
      isLianmai,
      LianmaiText,
      LianmaiOut,
      // 退出
      signout,
      // 下播提示
      downcast,
      // anchorName,
      // isAnchorName,
      // 取消密码
      cancelPassword,
      testValue,
      // 无人机按钮
      switchUAV,
      changeSwitchUAV,
      // webrtc 推流
      stop,
      // videoPubliser,
      start,

      monitorList,
      replaceFields,
      treeData,
      seach_map_point,
      searchMap,

      liveType,
      amplification,

      reconnectionButton,

      map_tennis_point,
      ref_map,

      handleChange,
      departmencode_show,

      clickNumber,
      peopleNumber,
      clickNumberValue,
      peopleNumberValue,

      switchLive,
      enlarge,
      agent
    };
  },
  unmounted() {
    window.removeEventListener('popstate', goBack, false);
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.player-setup-button {
  height: 30px;
  border-radius: 25px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
}
.wrapper.live-broadcast-content {
  position: relative;
  width: 1400px;
  margin: 20px auto 0;
  .live-player {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    // padding-bottom: 80px;
    .live-player-left {
      width: 74.358%;
      border-radius: 10px;
      .video-title {
        display: flex;
        align-content: center;
        background-color: #fff;
        border-radius: 10px 10px 0 0;
        padding: 0 30px 0 15px;
        align-items: center;
        .title-logo {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title-information {
          display: flex;
          width: 100%;
          margin-left: 15px;
          flex-direction: column;
          margin-top: 19px;
          margin-bottom: 7px;
          .title-information-top > button,
          .title-information-bottom > button {
            border-radius: 20px;
            height: 30px;
          }
          .title-information-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            margin-bottom: 4px;
            h3 {
              font-size: 19.5px;
              color: #333333;
              font-weight: bold;
            }
          }
          .title-information-bottom {
            font-size: 16px;
            height: 30px;
            justify-content: space-between;
            display: flex;
            .information-bottom-box {
              span {
                margin-right: 50px;
                color: #666666;
                img {
                  vertical-align: -3px;
                  width: 15px;
                  height: 15px;
                }
                strong {
                  vertical-align: middle;
                  margin-left: 5px;
                  font-weight: 400;
                }
              }
              .share-link-box {
                position: relative;
                cursor: pointer;
                .share-link {
                  .bullet-frame();
                  left: 35%;
                  right: unset;
                  &::before {
                    left: 55%;
                  }
                  .share-link-wrapper {
                    padding: 16px 20px;
                    .share-link-bottom {
                      display: flex;
                    }
                    .share-link-bottom {
                      height: 24px;
                      button {
                        height: 24px;
                        width: 74px;
                        padding: 0 10px;
                        margin-left: 5px;
                        background-color: #007fff;
                      }
                    }
                  }
                }
              }
            }
            & > button {
              background-color: #d4d4d4;
              border-color: #d4d4d4;
            }
          }
        }
      }

      .video-box {
        position: relative;
        height: 548px;
        .room-Player-image {
          position: absolute;
          background-size: cover;
          opacity: 0.5;
          width: 100%;
          height: 100%;
        }
        .room-Player-object {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          z-index: -999;
        }
        .room-Player {
          width: 100%;
          height: calc(100% - 50px);
        }
        .earlyWarning,
        .evenwheat,
        .signout,
        .start,
        .enlarge,
        .early-Warning,
        .reconnection {
          position: absolute;
          bottom: 100px;
          button {
            width: 90px;
            height: 40px;
            font-size: 16px;
            color: #0079ed;
            background-color: #fff;
            border-color: #fff;
            padding: 0 10px 0 5px;
            border-radius: 0px 20px 20px 0px;
          }
        }

        .reconnection {
          right: 0;
          bottom: 200px;
          button {
            border-radius: 20px 0 0 20px;
          }
        }

        .start {
          bottom: 300px;
          z-index: 999999;
        }

        .enlarge {
          bottom: 150px;
          z-index: 999999;
        }

        .evenwheat {
          bottom: 200px;
        }

        .early-Warning {
          bottom: 300px;
        }

        .signout {
          right: 0;
          z-index: 999999;
          bottom: 130px;
          button {
            border-radius: 20px 0 0 20px;
          }
        }
        .video-resources-player {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          width: 520px;
          height: calc(100% - 50px);
          h4 {
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            color: #fff;
            padding: 6px 0;
            text-align: center;
          }
          .resources-player-content {
            display: flex;
            flex-direction: column;
            height: calc(100%);
            .resources-player-item,
            .empty {
              flex: 1;
            }
            .empty {
              background: #333333;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #999999;
            }
            .resources-player-item {
              position: relative;
              height: 50%;
              .item-player-video {
                height: 100%;
              }
              .anticon {
                font-size: 11px;
                position: absolute;
                padding: 6px;
                border-radius: 50%;
                color: #fff;
                right: 11px;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.7);
                cursor: pointer;
              }
              .close-icon {
                top: 11px;
              }
              // .full-icon {
              //   bottom: 11px;
              // }
              .switch {
                position: absolute;
                font-size: 12px;
                cursor: pointer;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 11px;
                bottom: 58px;
                width: 90px;
                height: 27px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 14px;
                border: 1px solid rgba(255, 255, 255, 0.7);
              }
            }
          }
        }
        .player-setup {
          position: absolute;
          bottom: 0;
          padding: 10px 15px;
          width: 100%;
          display: flex;
          min-height: 50px;
          background-color: #000000;
          // border-radius: 0px 0px 10px 10px;
          .ant-select {
            color: #fff;
            width: 155px;
            margin-right: 20px;
            ::v-deep(.ant-select-selector) {
              .player-setup-button();
              .ant-select-selection-placeholder {
                color: #fff;
              }
            }
            ::v-deep(.ant-select-arrow) {
              color: #fff;
              top: 50%;
            }
          }
          button {
            .player-setup-button();
            &:nth-child(2) {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
  .history-box {
    margin-top: 30px;
    .history-title {
      display: flex;
      margin-bottom: 50px;
      align-items: baseline;
      div {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        background-color: #007ff0;
        margin-right: 8px;
        span {
          padding-left: 1px;
          color: #fff;
        }
      }
      h3 {
        font-size: 16px;
      }
    }
  }

  .vjs-control-bar {
    bottom: 50px;
  }
}
.close-box,
.password-box,
.kickOut-box {
  ::v-deep(.captcha-modal) {
    width: 400px !important;
    .ant-modal-content {
      overflow: hidden;
    }
    .ant-modal-footer {
      padding: 0;

      button {
        height: 28px;
        width: 74px;
        margin: 0;
      }
      button:first-child {
        margin-right: 25px;
      }
    }
  }
}
.close-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      .ant-modal-footer {
        margin: 30px 0;
      }
    }
  }
}
.password-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      padding: 0;
      .ant-modal-body {
        background-color: #fff;
        padding: 58px 116px 40px;
        text-align: center;
        div {
          margin-bottom: 20px;
        }
        input {
          height: 40px;
          border-radius: 5px;
        }
      }
      .ant-modal-footer {
        margin: 0 0 30px 0;
      }
    }
  }
}
.kickOut-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      .ant-modal-header {
        padding: 35px 0 35px;
      }
      .ant-modal-body {
        width: 280px;
        margin: 0 auto;
        text-align: center;
      }
      .ant-modal-footer {
        margin: 50px 0 30px;
      }
    }
  }
}
// .AnchorName {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   color: #fff;
//   top: 0;
//   font-size: 24px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.earlyWarning-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      padding: 0;
      .ant-modal-body {
        background-color: #fff;
        padding: 56px 40px 36px;
      }
      .itemBox {
        display: flex;
        span {
          flex: 1;
          display: block;
          color: #666666;
          font-size: 14px;
        }

        .itemRight {
          flex: 5;
        }
        textarea {
          padding: 0 !important;
          border-radius: 5px;
          height: 150px;
          resize: none;
        }
        textarea::placeholder {
          padding: 2px;
        }
        .ant-select-selector {
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(1) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(2) {
        margin: 20px 0;
      }
      .itemBox:nth-child(3) {
        div {
          height: 144px;
          border: 1px solid #d8d8d8;
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(4) {
        margin: 20px 0;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(5) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
#box-canvas {
  margin-bottom: 10px;
  position: relative;
}
::v-deep .box-canvas-img {
  position: absolute;
  left: 0;
  z-index: -1;
  width: auto;
  height: auto;
}

.reload-db {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.canvas {
  border: 1px solid #d3d3d3;
}
</style>
