<template>
  <div id="map" class="map"></div>
</template>

<script>
import { onMounted, watch, computed , ref } from 'vue';
import image from '@/assets/images/header/monitor.png';
import bus from '../bus';
import monitorSelect from '@/assets/images/header/monitor-select.png';
import currentPositioning from '@/assets/images/header/current-positioning.png';
import GeoTransform from 'geotransform';
import api from '@/services';
import ptd from '@/assets/imgs/ptd.png';
import { message } from 'ant-design-vue';
  import { getQueryVariable } from '@/utils/tool';

export default {
  props: {
    searchMap: {
      type: Array,
      default: [],
    },
    playList: {
      type: Array,
      default: []
    }
  },
  setup(props, content) {
    onMounted(() => {
      initMap();
      mapPoints();

      
      if(getQueryVariable('lon') && getQueryVariable('lat')) {
        const lon = getQueryVariable('lon')
        const lat = getQueryVariable('lat')
        let gt = new GeoTransform();
        gt.transform('WGS84', 'BD09', [lon, lat])

        let Icon = new BMap.Icon(currentPositioning, new BMap.Size(19, 25), { anchor: new BMap.Size(9, 25) });
        let marker = new BMap.Marker(new BMap.Point(lon, lat), { icon: Icon });
        map.addOverlay(marker); //将标注点添加到地图上
      }

    });

    //数据准备,
    let points = []; //原始点信息数组
    let bPoints = []; //百度化坐标数组。用于更新显示范围。
    let map;
    let gt = new GeoTransform();
    const mapPoints = async () => {
      const { data } = await api.mapPoints();
      data?.forEach((e, i) => {
        let value = gt.transform('WGS84', 'BD09', [e.longitude, e.latitude]);
        addMarker({ lng: value[0], lat: value[1], status: 1, id: e.id }); //增加对应该的轨迹点
      });
    };

    const playListValue = computed(() => {
      return JSON.parse(JSON.stringify(props.playList))
    });

    let mk = ref([])

    function addMarker(point) {
      //循环建立标注点
      let Icon = new BMap.Icon(image, new BMap.Size(19, 25), { anchor: new BMap.Size(9, 25) });
      let marker = new BMap.Marker(new BMap.Point(point.lng, point.lat), { icon: Icon });
      // map.addOverlay(marker);

      // var marker = new BMap.Marker(); //将点转化成标注点
      marker.disableMassClear();
      map.addOverlay(marker); //将标注点添加到地图上
      marker.addEventListener('click', async (e) => {
        const { data } = await api.getMapLive(point.id);
        console.log(data)
        content.emit('mapData', data);
        if(data.liveAddress && mk.value.length < 2) {
          if(mk.value.length > 0 && mk.value[0].data.id == data.id) {
            message.error('不可以连续点击相同点位监控')
          } else {
            marker.setIcon(new BMap.Icon(monitorSelect, new BMap.Size(24,39)));
            mk.value.push({
              ml_v: marker,
              data
            })
          }
        }
      });
     
    }

    watch(
      playListValue,
      (newValue, oldValue) => {
        // console.log(newValue, oldValue)
        // console.log(mk.value)
        if(newValue.length < oldValue.length) {
          if(oldValue.length == 1 && mk.value.length > 0) {
            mk.value[0].ml_v.setIcon(new BMap.Icon(image, new BMap.Size(19, 25), { anchor: new BMap.Size(9, 25) }))
            mk.value = []
          } else {
            for(let i = 0; i <= mk.value.length; i++) {
              if(mk.value[i]?.data.id != newValue[i]?.resourcesId) {
                mk.value[i].ml_v.setIcon(new BMap.Icon(image, new BMap.Size(19, 25), { anchor: new BMap.Size(9, 25) }))
                mk.value.splice(i, 1);
              }
            }
          }
        }
      },{deep:true}
    );
    

    // 添加轨迹
    function setTrack(points) {
      map.clearOverlays();
      // 建立标注点
      var marker = new BMap.Marker(points[points.length - 1]); //将点转化成标注点
      map.addOverlay(marker); //将标注点添加到地图上
      // 增加折线
      let coordinates = points.map(i => new BMap.Point(i.lng, i.lat));
      let polyline = new BMap.Polyline(coordinates, {
        strokeColor: 'blue',
        strokeWeight: 5,
        strokeOpacity: 1
      });
      map.addOverlay(polyline);
    }

    //随机生成新的点，加入到轨迹中。
    function dynamicLine(data) {
      var lng = data.flightTrackVo.lon;
      var lat = data.flightTrackVo.lat;
      let newCoordinate = gt.transform('WGS84', 'BD09', [lng, lat]);
      console.log(newCoordinate);
      var id = getRandom(1000);
      var newLinePoints = [];
      var point = { lng: newCoordinate[0], lat: newCoordinate[1], status: 1, id: id };
      var len;

      points.push(point);
      bPoints.push(new BMap.Point(newCoordinate[0], newCoordinate[1]));
      len = points.length;
      newLinePoints = points.slice(len - 2, len); //最后两个点用来画线。

      setTrack(points);
      // setZoom(bPoints);
    }

    // 获取随机数
    function getRandom(n) {
      return Math.floor(Math.random() * n + 1);
    }

    //根据点信息实时更新地图显示范围，让轨迹完整显示。设置新的中心点和显示级别
    function setZoom(bPoints) {
      var view = map.getViewport(eval(bPoints));
      var mapZoom = view.zoom;
      var centerPoint = view.center;
      map.centerAndZoom(centerPoint, mapZoom);
    }

    const initMap = () => {
      map = new BMap.Map('map');
      map.centerAndZoom(new BMap.Point(120.5811424828, 31.3010678543), 11); // 设置中心点
      map.addControl(new BMap.MapTypeControl()); //可拖拽
      map.enableScrollWheelZoom(true); //滚轮实现方法缩小
    };

    bus.on('mapPoint', data => {
      dynamicLine(data);
    });

   
    watch(() => props.searchMap, (newProps, oldProps) => {
      map.clearOverlays();
      newProps?.forEach((e, i) => {
        let value = gt.transform('WGS84', 'BD09', [e.longitude, e.latitude]);
        addMarker({ lng: value[0], lat: value[1], status: 1, id: e.id }); //增加对应该的轨迹点
      });
    },{deep:true} );
    
    // 清除制定类型的
    function deletePoint(){
      var allOverlay = map.getOverlays();
      for (var i = 0; i < allOverlay.length -1; i++){
        if(allOverlay[i].customData){
          allOverlay[i].enableMassClear();
          map.removeOverlay(allOverlay[i]);
        }
      }
    }

    const mapSingleTennis = async () => {
      deletePoint()
      const { data } =  await api.mapSingleTennis();
      mapSingleTennisAddPoint(data)
    }

    function mapSingleTennisAddPoint(data) {
      // 生成坐标点
      for (var i = 0; i < data.length; i++) {
        // 一个坐标对应一个mark的生成
        var point = new BMap.Point(data[i].longitude, data[i].latitude);
        if(point.lng != 0 && point.lat != 0) {
          mapSingleTennisAddMark(point, data[i]);
        }
      }
    };
    
    function mapSingleTennisAddMark(point, obj) {
      console.log(point)
      // 生成图像标注
      let Icon = new BMap.Icon(ptd, new BMap.Size(25, 25));
      let mark = new BMap.Marker(point, {icon: Icon});
      mark.disableMassClear();
      mark.customData = {'sing': true}
      map.addOverlay(mark);
      // 创建一个文本标注实例
      var lable = new BMap.Label(`<div class="info-box">
                                  <p>名字：${obj.dispName}</p>
                                  <p>呼号：${obj.pdtDeviceNumber}</p>
                                  </div>`);
      // 清除百度地图自带样式
      lable.setStyle({
        border: 'none',
        border: '1px solid rgba(36,110,221, .5)'
      });
      // 设置标注的地理坐标
      lable.setPosition(point);
      // 默认不显示文本标注
      lable.hide();
      // 在全景场景内添加覆盖物
      map.addOverlay(lable);
      mark.addEventListener('mouseover', function () {
        lable.show();
      });
      mark.addEventListener('mouseout', function () {
        lable.hide();
      });
    }
  

    return {
      initMap,
      mapSingleTennis
    };
  }
};
</script>
<style lang="less" scoped>
.map {
  width: 100%;
  margin-top: 20px;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #666666;
  ::v-deep(.BMap_cpyCtrl),
  ::v-deep(.anchorBL) {
    display: none;
  }
}

::v-deep(.anchorTR) {
  display: none;
}
</style>
